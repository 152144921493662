<!-- Localized -->
<template>
    <div class="flex  flex-wrap md:flex-row flex-col radio_options">
        <div
            class="form-check flex pt-2 mr-4 radio h-full"
            v-for="(option, idx) in getOptions"
            :key="option.id+idx"
        >
            <label class="flex gap-2.5">
                <span class="h-4 min-h-4 w-4 min-w-4">
                    <input
                        class="form-check-input appearance-none rounded-full border-solid h-4 w-4 border bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        :class="{
                            'border-box-stroke': !error,
                            'border-red-600': error,
                        }"
                        :name="getName"
                        v-model="getValue"
                        :value="option.id"
                        :disabled="disabled || formAlreadySubmitted"
                        type="radio"
                        :id="getName + option.id"
                        @input="handleFieldValueChnage"
                        @click="handleOptionClick(option.id)"
                    />
                </span>
                <!-- :value="option" v-model="getValue"  -->
                <span class="form-check-label inline-block text-gray-800 cursor-pointer capitalize"> {{option.option_label}} </span>
            </label>
        </div>
    </div>
</template>

<script>
/* option.id is used instead of option.option_value to avoid wrong behavior in case of same values */

export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
        path: {
            type: String,
            require: true,
        },
        error: {
            type: Boolean,
            default: false,
        },
        value: String,
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false
        },
        // is used to prview filled form before its submitting:
        previewForm:{
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        formAlreadySubmitted: {
            type: Boolean,
        }
    },
    data() {
        return {
            selected: null,
        }
    },
    created() {
        if (this.value) {
            this.selected = this.getOptions.find(opt => opt.option_value === this.value)?.id || null;
        } else if (this.defaultOption) this.getValue = this.defaultOption.id;
    },
    watch: {
        value(val) {
            this.selected = this.getOptions.find(opt => opt.option_value === val)?.id || null;
        }
    }, 
    computed: {
        getOptions() {
            return this.fieldData.options || [];
        },
        getName() {
            return this.path + `_preview:${this.previewForm}`;
        },
        defaultOption() {
            return this.getOptions.find(opt => opt.is_default);
        },
        getValue: {
            get() {
                // return this.fieldData.value ? this.getOptions.find(opt => opt.option_value === this.fieldData.value)?.id : null;
                return this.selected;
            },
            set(id) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.selected = id;
                const result = this.getOptions.find(opt => opt.id === id)?.option_value || null;
                this.$emit('input', result);
            }
        },
    },
    methods: {
        handleFieldValueChnage(event) {
            this.$emit('handleFieldValueChnage', event)
        },
        // handle click to deselect option because selection of the same value doesn't trigger an input event
        handleOptionClick(value) {
            if (this.selected === value) this.getValue = null;
        },
    }
};
</script>

<style>
.checked\:bg-blue-600:checked {
    --bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--bg-opacity));
}
.form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%222%22 fill=%22%23fff%22/%3E%3C/svg%3E");
}
.checked\:border-blue-600:checked {
    --border-opacity: 1;
    border-color: rgb(37 99 235 / var(--border-opacity));
}
.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}
</style>
<style lang="scss">
.form-check-input:checked {
    // background-color: #0d6efd;
    // border-color: #0d6efd;
    @apply border-primary bg-primary;
}

.radio_options{
    width:100%;

    .form-check{
        display: flex;
        flex-direction: column;
      flex-wrap: wrap;
    }

    label{
    }
    .radio{
        width:fit-content;
        padding-left: 2%;
        padding-right: 2%;
        border: none;
    }
}

@media screen and (max-width:768px) and (max-height:1400px){
    .radio_options{
      width:100%;
      display: flex !important;
      flex-wrap: wrap;
      
      flex-direction: row !important;
      padding: 0 !important;
    }

    .radio_options .form-check{
        width:fit-content;
        border: none;
        padding: 0 !important;

        label{
            width: fit-content;
            cursor: pointer;
            padding: 0;
        }
       .form-check-input{
           cursor: pointer;

       }
    }

}
</style>